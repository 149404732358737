export default () => {
  function handleFreespeePageTracking() {
    if (window.__fs_dncs_instance) {
      window.__fs_dncs_instance.trackPage(window.location.href);
    }
  }
  onMounted(() => {
    handleFreespeePageTracking();
  });
};
